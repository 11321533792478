<template>
    <div>
        <div class="w-full h-full flex flex-col">
            <div
                class="flex flex-row justify-center w-full text-xl p-6 bg-grey text-white font-bold font-sans"
            >
                Chat in any Language
            </div>
            <div
                class="onboarding w-full lg:w-60onboarding0 lg:m-auto lg:rounded-lg lg:shadow-lg lg:h-auto"
            >
                <component
                    ref="formComponent"
                    :is="component"
                    :form="form"
                    :loading="loading"
                    @submit="submitForm"
                    @goBack="goToPrevStage"
                    @goToStage="goToStage"
                    @resendCode="resendCode"
                />
                <!-- <component
                    ref="formComponent"
                    :is="'MobileNumberForm'"
                    :form="form"
                    :loading="loading"
                    @submit="submitForm"
                    @goBack="goToPrevStage"
                    @goToStage="goToStage"
                    @resendCode="resendCode"
                />
                <component
                    ref="formComponent"
                    :is="'PhoneVerificationForm'"
                    :form="form"
                    :loading="loading"
                    @submit="submitForm"
                    @goBack="goToPrevStage"
                    @goToStage="goToStage"
                    @resendCode="resendCode"
                />
                <component
                    ref="formComponent"
                    :is="'RegistrationForm'"
                    :form="form"
                    :loading="loading"
                    @submit="submitForm"
                    @goBack="goToPrevStage"
                    @goToStage="goToStage"
                    @resendCode="resendCode"
                /> -->
            </div>
        </div>
    </div>
</template>

<script>
import MobileNumberForm from '@/components/onboarding/MobileNumberForm.vue'
import PhoneVerificationForm from '@/components/onboarding/PhoneVerificationForm.vue'
import RegistrationForm from '@/components/onboarding/RegistrationForm.vue'

import {API_STATUSES, ON_BOARDING_MESSAGES, STAGES} from '@/config/constants.js'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'Onboarding',
    components: {MobileNumberForm, PhoneVerificationForm, RegistrationForm},
    data() {
        return {
            isValid: true,
            stage: STAGES.NONE,
            component: null,
            loading: false,
            form: {
                phone: '',
                fullPhone: '',
                code: '',
                firstName: '',
                lastName: ''
            }
        }
    },
    created() {
        this.goToStage(STAGES.MOBILE_NUMBER_INPUT)
    },
    mounted() {
        this.clearValidate()
    },
    methods: {
        ...mapActions(['setToken']),
        goToPrevStage() {
            switch (this.stage) {
                case STAGES.MOBILE_NUMBER_INPUT:
                    this.goToStage(STAGES.NONE)
                    break
                case STAGES.PHONE_VERIFICATION:
                case STAGES.REGISTRATION:
                    this.goToStage(STAGES.MOBILE_NUMBER_INPUT)
                    break
            }
        },
        goToStage(stage) {
            this.stage = stage
        },
        showForm() {
            switch (this.stage) {
                case STAGES.MOBILE_NUMBER_INPUT:
                    this.component = 'MobileNumberForm'
                    break
                case STAGES.PHONE_VERIFICATION:
                    this.component = 'PhoneVerificationForm'
                    break
                case STAGES.REGISTRATION:
                    this.component = 'RegistrationForm'
                    break
                default:
                    this.component = null
            }
        },
        validate() {
            if (!this.$refs.formComponent || !this.$refs.formComponent.validate) return
            this.isValid = this.$refs.formComponent.validate()
            return this.isValid
        },
        clearValidate() {
            this.isValid = true
            if (!this.$refs.formComponent || !this.$refs.formComponent.validate) return
            if (this.$refs.formComponent.reset) this.$refs.formComponent.reset()
            else if (this.$refs.formComponent.clearValidate)
                this.$refs.formComponent.clearValidate()
            return this.isValid
        },
        submitForm() {
            this.clearValidate()
            this.validate()
            if (!this.isValid) return

            switch (this.stage) {
                case STAGES.MOBILE_NUMBER_INPUT:
                    this.searchPhone()
                    break
                case STAGES.PHONE_VERIFICATION:
                    this.verifyCode()
                    break
                case STAGES.REGISTRATION:
                    this.registerUser()
                    break
            }
        },
        searchPhone() {
            this.loading = true
            this.$http
                .get(`/search?MSISDN=${this.form.phone}`)
                .then(({data}) => {
                    if (data.STATUS && data.STATUS === API_STATUSES.FOUND) {
                        this.sendVerificationCode()
                    } else {
                        this.loading = false

                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: ON_BOARDING_MESSAGES.MOBILE_NOT_FOUND,
                            color: 'orange'
                        })
                        this.goToStage(STAGES.REGISTRATION)
                    }
                })
                .catch((e) => {
                    this.loading = false

                    if (e?.response.status === 404) {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: ON_BOARDING_MESSAGES.MOBILE_NOT_FOUND,
                            color: 'orange'
                        })
                        this.goToStage(STAGES.REGISTRATION)
                    } else {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'Sorry, an error occurred.',
                            color: 'red'
                        })
                    }
                })
        },
        sendVerificationCode() {
            this.loading = true
            this.$http
                .post(`/login`, {MSISDN: this.form.phone})
                .then(({data}) => {
                    console.debug('data', data)
                    this.loading = false

                    if (data.STATUS && data.STATUS === API_STATUSES.MESSAGE_SENT) {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'We have sent a verification code to your mobile, please check your messages.',
                            color: 'green'
                        })
                        this.goToStage(STAGES.PHONE_VERIFICATION)
                    } else {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'Invalid verification code, please enter the correct one. Please click on resend button if you want to receive another code.',
                            color: 'red'
                        })
                    }
                })
                .catch(() => {
                    this.loading = false

                    this.$store.commit('ALERT_IS_VISIBLE', true)
                    this.$store.commit('ALERT_DATA', {
                        text: 'Sorry, an error occurred.',
                        color: 'red'
                    })
                })
        },
        resendCode() {
            console.debug('resend code')

            this.sendVerificationCode()
        },
        verifyCode() {
            this.loading = true
            this.$http
                .post(`/auth`, {MSISDN: this.form.phone, OTC: this.form.code})
                .then(async ({data}) => {
                    console.debug('data', data)

                    if (data.STATUS && data.STATUS === API_STATUSES.LOGGED_IN) {
                        //TODO: push the data.TOKEN and user info name into store, we need to manage the auth system yet.
                        await this.setToken(data.TOKEN)
                        await this.$router.push({name: 'chat'})
                    }
                })
                .catch((e) => {
                    if (e?.response.status === 401) {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'Invalid verification code, please enter the correct one. Please click on resend button if you want to receive another code.',
                            color: 'red'
                        })
                    } else if (e?.response.status === 412) {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'The phone number is already logged in our system. Redirecting for now..',
                            color: 'orange'
                        })
                        // for now we go to the chat page, but TODO: logout current customer and re-login
                        // this.goToStage(STAGES.MOBILE_NUMBER_INPUT)
                        this.$router.push({name: 'chat'})
                    } else {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'Sorry, an error occurred.',
                            color: 'red'
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        registerUser() {
            this.loading = true
            this.$store.commit('SET_USER', {
                firstName: this.form.firstName,
                lastName: this.form.lastName
            })
            this.$http
                .post(`/register`, {
                    MSISDN: this.form.phone,
                    FirstName: this.form.firstName,
                    LastName: this.form.lastName
                })
                .then(({data}) => {
                    console.debug('data', data)

                    if (!data.STATUS) {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'Congratulations! You have successfully registered. Now you can start chatting.',
                            color: 'green'
                        })
                        this.sendVerificationCode()
                    } else if (data.STATUS && data.STATUS === API_STATUSES.EXISTS) {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: 'Phone number already exists in the system. Please try another number.',
                            color: 'red'
                        })
                    } else {
                        this.$store.commit('ALERT_IS_VISIBLE', true)
                        this.$store.commit('ALERT_DATA', {
                            text: data.ERROR || 'Registration failed due to an unknown error.',
                            color: 'red'
                        })
                    }
                })
                .catch(() => {
                    this.$store.commit('ALERT_IS_VISIBLE', true)
                    this.$store.commit('ALERT_DATA', {
                        text: 'Sorry, an error occurred.',
                        color: 'red'
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    computed: {
        ...mapGetters(['welcomeText'])
    },
    watch: {
        stage() {
            this.showForm()
        }
    }
}
</script>

<style>
.v-input__slot {
    @apply bg-grey-2 !important;
    @apply rounded-lg !important;
    @apply shadow-xl-light !important;
    @apply h-10 !important;
    @apply font-sans !important;
}
.theme--light.v-input .v-input__slot .v-text-field__slot > input {
    @apply text-black !important;
}
</style>
