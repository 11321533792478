const FormRules = {
    methods: {
        required: value => !!value || 'Required.',
        requiredNoText: value => !!value || '',
        min: v => v.length >= 8 || 'Min 8 characters',
        max: v => v.length <= 16 || 'Max 16 characters',
        numbersOnly: v => {
            if (!v || !v.trim() || !isNaN(parseInt(v)) && v >= 0) return true
            return 'Value has to be a number'
        },
        timeOnly: v => {
            const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
            
            if (!v || !v.trim() || timeRegex.test(v)) return true
            return 'Value should be in form hh:mm am|pm'
        }
    }
}

const ALLOWED_KEYCODE = [8, 37, 39, 46]

const FormEvents = {
    methods: {
        timeOnlyEvent(e) {
            const val = e.key
            if (!/[0-9:AaPpMm ]/.test(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        },
        numbersOnlyEvent(e) {
            // we try and convert it with the '+' operator
            const val = +e.key
            // if it's not NaN, then we have a number key press
            if (isNaN(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        }
    }
}

export {
    FormRules,
    FormEvents
}
