<template>
  <v-form
    class="mobile-number-form"
    v-model="isValid"
    ref="form"
    @submit.prevent="submitForm"
  >
    <div class="relative w-full mb-10">
        <div class="mx-auto relative h-24 mt-2 z-10 flex justify-center">
            <img class="narrator h-24" src="@/assets/images/language-bubbles.svg" />
        </div>
        <div
            style="top: 125px; width: 98%"
        >
            <h1 class="mb-3 px-6 ml-1 mt-4 font-bold font-sans text-lg">A verification code has been sent</h1>
        </div>
    </div>
    <div class="grid">
      <div class="col-span-1 flex flex-col -mb-2">
        <label class="flex flex-row justify-start text-sm text-center mx-2 mb-2 leading-4 font-sans">
            Enter code sent to your mobile
        </label>
        <v-text-field
          dense
          outlined
          v-model="form.code"
          :rules="[required]"
        ></v-text-field>
      </div>
      <div class="col-span-1 flex flex-col">
        <label class="flex flex-row justify-start text-sm text-center mx-2 mb-2 leading-4 font-sans">
            Mobile number
        </label>
        <v-text-field
          dense
          outlined
          v-model="form.phone"
          readonly
        ></v-text-field>
      </div>
    </div>
    <v-btn
        class="font-sans text-xl bg-grey infoLight2--text w-full py-6 rounded-full mb-2"
        depressed
        type="submit"
        color="info"
        style="font-size: 0.975rem"
        :loading="loading"
    >Confirm</v-btn>
    <v-btn
        class="font-sans text-xl bg-grey infoLight2--text w-full py-6 rounded-full"
        color="warning"
        depressed
        style="font-size: 0.975rem"
        @click="resendCode"
    >Resend</v-btn>
    <div class="p-5 mt-3 text-center">
      If you think you wrote the wrong mobile number, please
      <a @click.prevent="goBack">go back</a> and enter it again.
    </div>
  </v-form>
</template>

<script>
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
  name: 'PhoneVerificationForm',
  mixins: [FormRules],
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isValid: true
    }
  },
  mounted() {
    this.form.code = ''
  },
  methods: {
    submitForm() {
      this.$refs.form.validate()
      if (!this.isValid) return

      this.$emit('submit')
    },
    resendCode() {
      this.$emit('resendCode')
    },
    goBack() {
      this.$emit('goBack')
    }
  }
}
</script>
